import type { FC, ReactNode } from 'react';
import React, { Fragment, useState } from 'react';
import { useIntl } from 'react-intl-next';

import MenuGroup from '@atlaskit/menu/menu-group';

import { VIEW_PAGE, WHITEBOARD_BOARD, DATABASE_VIEW, EMBED_VIEW } from '@confluence/named-routes';
import { useIsSuperAdmin } from '@confluence/super-admin';
import type { HoverPageCardProps } from '@confluence/page-card';

import { SpaceViewsListItem } from './SpaceViewsListItem';

export type SpaceViewsPage = {
	title?: string | null;
	id: string | null;
	hasRestrictions: boolean;
	hasInheritedRestrictions: boolean;
	displayEmoji: string;
	type?: string;
};

type SpaceViewsListProps = {
	pagesList: Array<SpaceViewsPage>;
	onItemClick?: (id: string, index?: number) => void;
	spaceKey?: string;
	contentId?: string;
	highlightedPages?: string[];
	shouldRenderAfterIcon?: boolean;
	renderTitle?: ({ title }: { title: string }) => ReactNode;
} & Partial<HoverPageCardProps>;

const getUrlByType = (type) => {
	const typeToUrl = {
		page: VIEW_PAGE,
		whiteboard: WHITEBOARD_BOARD,
		database: DATABASE_VIEW,
		embed: EMBED_VIEW,
	};

	return type && typeToUrl[type] ? typeToUrl[type] : VIEW_PAGE;
};

export const SpaceViewsList: FC<SpaceViewsListProps> = ({
	pagesList,
	onItemClick,
	spaceKey,
	contentId,
	highlightedPages = [],
	isHoverPageCardOptedIn,
	closeAllHoverPageCards = () => {},
	onEnterHoverTarget = () => {},
	onLeaveHoverTarget = () => {},
	onBlurHoverTarget = () => {},
	shouldRenderAfterIcon,
	renderTitle = ({ title }) => title,
}) => {
	const intl = useIntl();
	const { isSuperAdmin } = useIsSuperAdmin();
	const [openQuickActionsId, setOpenQuickActionsId] = useState<string | null>(null);
	const [focusedQuickActionsId, setFocusedQuickActionsId] = useState<string | null>(null);
	const [isEditingTitleId, setIsEditingTitleId] = useState<string | null>(null);
	return (
		<Fragment>
			<MenuGroup>
				{pagesList.map(
					({ title, id, hasRestrictions, hasInheritedRestrictions, displayEmoji, type }, index) => {
						const isCurrentPage = Boolean(contentId && contentId === id);
						let parsedId = id;
						// databases and embeds currently have their id returned as 'activation/ACTIVATION_ID/ENTITY_ID', so we are parsing the entity id
						if ((type === 'database' || type === 'embed') && id) {
							parsedId = id.split('/').pop() || null;
						}
						const contentLink =
							parsedId && spaceKey
								? getUrlByType(type).toUrl({
										spaceKey,
										contentId: parsedId,
									})
								: '';

						const item = {
							id: id || '',
							hasRestrictions,
							hasInheritedRestrictions,
							data: {
								type,
							},
						};
						const highlight = id ? highlightedPages.includes(id) : false;
						return (
							<SpaceViewsListItem
								intl={intl}
								url={contentLink}
								title={title || ' '}
								displayEmoji={displayEmoji}
								contentType={type}
								onItemClick={onItemClick}
								key={id}
								index={index}
								isSelected={isCurrentPage}
								spaceKey={spaceKey || ''}
								item={item}
								isHoverPageCardOptedIn={isHoverPageCardOptedIn}
								closeAllHoverPageCards={closeAllHoverPageCards}
								highlight={highlight}
								isSuperAdmin={isSuperAdmin}
								isQuickActionsOpen={openQuickActionsId === item.id}
								setOpenQuickActionsId={setOpenQuickActionsId}
								isQuickActionsFocused={focusedQuickActionsId === item.id}
								setFocusedQuickActionsId={setFocusedQuickActionsId}
								isEditingTitleId={isEditingTitleId}
								setIsEditingTitleId={setIsEditingTitleId}
								onEnterHoverTarget={onEnterHoverTarget}
								onLeaveHoverTarget={onLeaveHoverTarget}
								onBlurHoverTarget={onBlurHoverTarget as (e) => void}
								shouldRenderAfterIcon={shouldRenderAfterIcon}
								renderTitle={renderTitle}
							/>
						);
					},
				)}
			</MenuGroup>
		</Fragment>
	);
};
