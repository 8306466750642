import type { FC } from 'react';
import React from 'react';

import Tooltip from '@atlaskit/tooltip/Tooltip';

type PageTreeTooltipProps = {
	title: string;
	isQuickActionsOpen: boolean;
	children?: React.ReactNode;
};

/**
 * Wraps the page tree item with tooltip if page card popups is off. Otherwise, returns the page tree item as is.
 * @param title - the content of the tooltip. In this case, the page's title
 * @param isQuickActionsOpen - if user has opened quick actions dialog. If open, tooltip should not show.
 */
export const PageTreeTooltip: FC<PageTreeTooltipProps> = ({
	children,
	title,
	isQuickActionsOpen,
}) => (
	<Tooltip
		content={!isQuickActionsOpen && title ? title : null}
		position="top"
		delay={750}
		hideTooltipOnMouseDown
	>
		{children}
	</Tooltip>
);
