import type { FC } from 'react';
import React, { Fragment } from 'react';

import type { InlineRenameProps } from './index';
import { InlineRename } from './index';

export const ConditionalInlineRename: FC<InlineRenameProps> = ({
	children,
	isEditingTitle,
	...props
}) => {
	if (isEditingTitle) {
		return (
			<InlineRename {...props} isEditingTitle={isEditingTitle}>
				{children}
			</InlineRename>
		);
	}
	return <Fragment>{children}</Fragment>;
};
