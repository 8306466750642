import React from 'react';

import { LoadableAfterPaint } from '@confluence/loadable';

import { EmojiPlaceholder } from '../src/EmojiPlaceholder';

export const LazyEmojiComponentLoader = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-srcEmojiComponent" */ '../src/EmojiComponent'))
			.EmojiComponent,
	loading: (props) => (props.emoji ? <EmojiPlaceholder {...props} /> : null),
});

export const EmojiTitlePopoverLoader = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-srcemoji-titleEmojiTitlePopover" */ '../src/emoji-title/EmojiTitlePopover'
			)
		).EmojiTitlePopover,
});

export const EmojiTitleButtonLoader = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-srcemoji-titleEmojiTitleButton" */ '../src/emoji-title/EmojiTitleButton'
			)
		).EmojiTitleButton,
});

export const EmojiTitleSelection = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-srcemoji-titleEmojiTitleSelection" */ '../src/emoji-title/EmojiTitleSelection'
			)
		).EmojiTitleSelection,
});
