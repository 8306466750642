import React from 'react';
import { css } from '@compiled/react';

import FadeIn from '@atlaskit/motion/fade-in';
import { N20A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { reduceMotionAsPerUserPreference } from '@atlaskit/motion/accessibility';

const skeletonWrapperStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage
	...reduceMotionAsPerUserPreference,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

const emojiSkeletonStyles = css({
	display: 'inline-block',
	background: token('color.background.neutral', N20A),
	borderRadius: '3px',
	margin: token('space.0', '0px'),
});

export const EmojiPlaceholder = (props: { height: number; loadingMargin?: string }) => {
	const PLACEHOLDER_SIZE = props.height || 26;
	return (
		<FadeIn entranceDirection="left">
			{(animationProps) => (
				<span
					css={skeletonWrapperStyles}
					{...animationProps}
					data-testid="emoji-placeholder"
					data-css-target="emoji-placeholder"
				>
					<span
						css={emojiSkeletonStyles}
						style={{
							width: `${PLACEHOLDER_SIZE}px`,
							height: `${PLACEHOLDER_SIZE}px`,
							fontSize: `${PLACEHOLDER_SIZE}px`,
							lineHeight: `${PLACEHOLDER_SIZE}px`,
							margin: props.loadingMargin,
						}}
					/>
				</span>
			)}
		</FadeIn>
	);
};
