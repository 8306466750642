import type { FC } from 'react';
import React, { Fragment } from 'react';

import { cleanExcerpt } from '@confluence/search-utils/entry-points/excerpt';

import { PageTreeTooltip } from './PageTreeTooltip';

type ConditionalTreeItemTooltipProps = {
	isHoverPageCardOptedIn: boolean;
	title: string;
	isQuickActionsOpen: boolean;
	children?: React.ReactNode;
};

/**
 * Wraps the page tree item with tooltip if page card popups is off. Otherwise, returns the page tree item as is.
 * @param isHoverPageCardOptedIn - the value from useHoverPageCardsOptStatus. It is true if user should be shown popups
 * @param title - the content of the tooltip. In this case, the page's title
 * @param isQuickActionsOpen - if user has opened quick actions dialog. If open, tooltip should not show.
 */
export const ConditionalTreeItemTooltip: FC<ConditionalTreeItemTooltipProps> = ({
	isHoverPageCardOptedIn,
	children,
	title,
	isQuickActionsOpen,
}) => {
	return isHoverPageCardOptedIn ? (
		<Fragment>{children}</Fragment>
	) : (
		<PageTreeTooltip title={cleanExcerpt(title)} isQuickActionsOpen={isQuickActionsOpen}>
			{children}
		</PageTreeTooltip>
	);
};
