import type { ReactElement } from 'react';
import React from 'react';

const SPECIAL_CHARS_REGEX = /^[!#$%^&*() ,.<>?\/\[\]_\-+=`~\|:;{}\\]+/;

/**
 * Make excerpt more readable by stripping leading non-alpha characters,
 * adding an ellipses prefix and suffix, replacing line breaks with seperators,
 * and bolding search terms.
 */
export const processExcerpt = (excerpt: string) => {
	excerpt = excerpt.trim();

	if (!excerpt) {
		return [<span key="empty" />];
	}

	const beginsWithHighlight = excerpt.slice(0, 8) === '@@@hl@@@';
	const beginsWithUrl =
		excerpt.slice(0, 4) === 'http' || (beginsWithHighlight && excerpt.slice(8, 12) === 'http');

	// Strip leading special characters unless the excerpt begins with a highlight
	if (!beginsWithHighlight) {
		excerpt = excerpt.replace(SPECIAL_CHARS_REGEX, '');
	}

	// Add ellipses prefix if beginning of excerpt isn't a URL and begins with a lowercase letter (unbolded or bolded)
	if (
		!beginsWithUrl &&
		((!beginsWithHighlight && excerpt[0] && excerpt[0] === excerpt[0].toLowerCase()) ||
			(beginsWithHighlight && excerpt[8] && excerpt[8] === excerpt[8].toLowerCase()))
	) {
		excerpt = '...'.concat(excerpt);
	}

	// Add elipses suffix if excerpt doesn't end with ending punctuation
	if (!excerpt.slice(-1).match(/[.!?]/)) {
		excerpt = excerpt.concat('...');
	}

	// Replace line breaks with seperators
	excerpt = excerpt.replace(/\n+/g, ' · ');

	return enrichExcerpt(excerpt);
};

/**
 * Replaces highlight markers with <strong> tags.
 * Typically used for highlighting search terms in excerpts.
 */
export const enrichExcerpt = (excerpt: string) => {
	const res: ReactElement[] = [];
	let pre, textMatch;
	let i = 0;
	while (excerpt) {
		const match = excerpt.match(/(.*?)@@@hl@@@(.*?)@@@endhl@@@(.*)/s);
		if (!match) {
			res.push(<span key={excerpt}>{excerpt}</span>);
			break;
		}
		[, pre, textMatch, excerpt] = match;
		if (pre) {
			res.push(<span key={`pre-${i}`}>{pre}</span>);
		}
		if (textMatch) {
			res.push(<strong key={`textMatch-${i}`}>{textMatch}</strong>);
		}
		i++;
	}
	return res;
};

/**
 * Strip highlight markers from text
 */
export const cleanExcerpt = (text: string) =>
	text && text.replace(/@@@hl@@@/g, '').replace(/@@@endhl@@@/g, '');
