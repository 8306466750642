export const CONTENT_TREE_TYPES = ['page', 'whiteboard', 'database', 'folder'] as const;
export type ContentTreeTypes = (typeof CONTENT_TREE_TYPES)[number];

export const isContentTreeType = (obj: unknown): obj is ContentTreeTypes =>
	typeof obj === 'string' && CONTENT_TREE_TYPES.includes(obj as ContentTreeTypes);

export const toContentTreeType = (obj: unknown): ContentTreeTypes | undefined =>
	isContentTreeType(obj) ? obj : undefined;

export const includesValidContentTreeType = (contentType: string, isNav4Enabled?: boolean) => {
	const contentTreeType = toContentTreeType(contentType);
	if (!isNav4Enabled && contentTreeType === 'page') {
		return false;
	}
	return !!contentTreeType && CONTENT_TREE_TYPES.includes(contentTreeType);
};
